
import NavFactory from "@/client/extensions/helpers/navFactory.js";

export default async ({ asyncOps, store }) => {
  let navItems;
  try {
   let  importedNavItemsFactory = await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await importedNavItemsFactory.default({ asyncOps, store });
  } catch (e) {
    return {};
  }

  let navFactory = new NavFactory(navItems);

  let articleItemCopy = navFactory.getItemChildCopy("entities", "article");
  let categoryItemCopy = navFactory.getItemChildCopy("entities", "category");
  let tagItemCopy = navFactory.getItemChildCopy("entities", "tag");
  let termItem = {
    icon: "binder",
    label: "ag.nav.adminSideNav.terms",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "entity-list",
          params: { entityType: "term" },
        },
      },
    },
  };
  navFactory
    .setItemOrder("generalTitle", 1)
    .updateItem("generalTitle", "label", "ag.nav.adminSideNav.generalTitleLabel")
    .addItemAfter("generalTitle", "article", articleItemCopy)
    .addItemAfter("article", "category", categoryItemCopy)
  .addItemAfter("article", "term", termItem)
    .addItemAfter("category", "tag", tagItemCopy)
    .moveItemAfter("media", "tag")
    .moveItemAfter("entities", "media");

  return navFactory.getItems();
};
